// #region Children Prop
/*  React 18 dropped implicit children prop. 
    Type error will occur: "Property 'children' does not exist on type 'IntrinsicAttributes'""
    This region covers options to address this change.
 */

/*
    The below module can be used to override react types to @types/react v17 
    and thus suppress the children prop warnings.
    However, it's better practice to explicitly define children as a viable prop.
 */

// import * as React from '@types/react';

// declare module 'react' {
//   interface FunctionComponent<P = {}> {
//     (props: PropsWithChildren<P>, context?: any): ReactElement<any, any> | null;
//   }
// }

/*
    The below exports are ways to explicitly define children as viable props.
 */

// Use this function to adjust components from dependencies.
export function componentWithChildren<Props>(
  Component: React.ComponentType<Props>
) {
  return Component as React.ComponentType<
    Props & { children: React.ReactNode }
  >;
}

// Use this type as "FC<Props>" for Pre-Con's own FC.
export type Props = {
  children?: React.ReactNode;
};

// #endregion
