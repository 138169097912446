import React from "react";
import { CurrentEstimateProvider as _CurrentEstimateProvider } from "./modules/estimates/context/CurrentEstimateContext";
import { CodebooksProvider as _CodebooksProvider } from "./modules/estimates/context/CodebooksContext";
import { SolcitationProvider as _SolcitationProvider } from "./modules/quote-management/context/solicitation-context";
import { ProposalProvider as _ProposalProvider } from "modules/quote-management/context/proposal-context";
import { VendorCommunicationProvider as _VendorCommunicationProvider } from "modules/quote-management/context/vendor-communication-context";
import { RecentEstimateProvider as _RecentEstimateProvider } from "modules/estimates/components/recent-estimates/use-recent-estimates";
import { TutorialProvider as _TutorialProvider } from "./modules/tutorials/use-tutorial";
import { HcssConnectProvider as _HcssConnectProvider } from "core/services/hcss-connect";
import { DevTools as _DevTools } from "./dev-tools";
import { RestfulProvider as _RestfulProvider } from "restful-react";
import { useHcssToken } from "modules/account";
import { componentWithChildren, Props } from "react-helper";

interface AppProvidersProps {
  children?: React.ReactNode;
}
export const AppProviders = ({ children }: AppProvidersProps) => {
  const CurrentEstimateProvider = componentWithChildren(
    _CurrentEstimateProvider
  );
  const CodebooksProvider = componentWithChildren(_CodebooksProvider);
  const SolcitationProvider = componentWithChildren(_SolcitationProvider);
  const ProposalProvider = componentWithChildren(_ProposalProvider);
  const VendorCommunicationProvider = componentWithChildren(
    _VendorCommunicationProvider
  );
  const RecentEstimateProvider = componentWithChildren(_RecentEstimateProvider);
  const TutorialProvider = componentWithChildren(_TutorialProvider);
  const HcssConnectProvider = componentWithChildren(_HcssConnectProvider);
  const DevTools = componentWithChildren(_DevTools);

  return (
    <RestfulReactProvider>
      <CurrentEstimateProvider>
        <RecentEstimateProvider>
          <CodebooksProvider>
            <SolcitationProvider>
              <ProposalProvider>
                <VendorCommunicationProvider>
                  <HcssConnectProvider>
                    <TutorialProvider>
                      <DevTools>{children}</DevTools>
                    </TutorialProvider>
                  </HcssConnectProvider>
                </VendorCommunicationProvider>
              </ProposalProvider>
            </SolcitationProvider>
          </CodebooksProvider>
        </RecentEstimateProvider>
      </CurrentEstimateProvider>
    </RestfulReactProvider>
  );
};

const RestfulReactProvider: React.FC<Props> = ({ children }) => {
  const token = useHcssToken();
  const RestfulProvider = componentWithChildren(_RestfulProvider);

  return (
    <RestfulProvider
      base=""
      requestOptions={{ headers: { Authorization: `BEARER ${token}` } }}
    >
      {children}
    </RestfulProvider>
  );
};
