import { strings } from "localization";

export enum ReportTypeEnum {
  Unused,
  Missing,
  Utilized
}

export interface ReportTypeOption {
  name: string;
  type: ReportTypeEnum;
  description: string;
}

export interface ReportTypeOptions {
  [key: string]: ReportTypeOption;
}

export const reportTypeOptions: ReportTypeOptions = {
  Unused: {
    name: strings.estimates.codebooks.unused.subtitle,
    type: ReportTypeEnum.Unused,
    description: strings.estimates.codebooks.unused.text
  },
  Missing: {
    name: strings.estimates.codebooks.missing.subtitle,
    type: ReportTypeEnum.Missing,
    description: strings.estimates.codebooks.missing.text
  },
  Utilized: {
    name: strings.estimates.codebooks.utilized.subtitle,
    type: ReportTypeEnum.Utilized,
    description: strings.estimates.codebooks.utilized.text
  }
};
