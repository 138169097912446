import constate from "constate";
import { useCallback, useMemo, useState } from "react";
import { MissingActivity, UnusedActivity, selectors } from "../state";
import { useSelector } from "react-redux";
import {
  ReportTypeEnum,
  ReportTypeOption,
  reportTypeOptions
} from "../components/codebooks/ReportTypeOptions";
import moment from "moment";

export interface CodebooksContext {
  currentDivisionId?: string;
  setCurrentDivisionId: (id: string | undefined) => void;
  unusedData: UnusedActivity[];
  missingLoaded: boolean;
  utilizedLoaded: boolean;
  utilizedDetailsLoaded: boolean;
  unusedLoaded: boolean;
  missingData: MissingActivity[];
  reportType: ReportTypeOption;
  setReportType: (type: ReportTypeOption) => void;
  selectedUtilizedCode: string;
  setSelectedUtilizedCode: (code: string) => void;
  isCurrentReportOverflow: () => boolean;
  hasNoCodebooksData: boolean;
  codebooksTimestamp: string;
  estimateTimestamp: string;
}
const useCodebooks = (): CodebooksContext => {
  const [currentDivisionId, setCurrentDivisionId] = useState<
    string | undefined
  >("");
  const [reportType, setReportType] = useState<ReportTypeOption>(
    reportTypeOptions.Unused
  );
  const [selectedUtilizedCode, setSelectedUtilizedCode] = useState<string>("");

  const utilizedLoaded = useSelector(
    selectors.getActivityCodebookUtilizedLoaded
  );
  const utilizedDetailsLoaded = useSelector(
    selectors.getActivityCodebookUtilizedDetailsLoaded
  );
  const allUtilizedData = useSelector(selectors.getActivityCodebookUtilized);
  const unusedLoaded = useSelector(selectors.getActivityCodebookUnusedLoaded);
  const allUnusedData = useSelector(selectors.getActivityCodebookUnused);
  const missingLoaded = useSelector(selectors.getActivityCodebookMissingLoaded);
  const allMissingData = useSelector(selectors.getActivityCodebookMissing);
  const overflowStatus = useSelector(
    selectors.getActivityCodebooksOverflowStatus
  );
  const codebooks = useSelector(selectors.getLastProcessedActivityCodebooks);
  const estimates = useSelector(selectors.getAllEstimates);
  const formatTime = (time: string) =>
    `${moment(time).format("L")} ${moment(time).format("LT")}`;

  const isCurrentReportOverflow = useCallback(() => {
    switch (reportType.type) {
      case ReportTypeEnum.Missing:
        return overflowStatus.missingOverflow ?? false;
      case ReportTypeEnum.Utilized:
        return overflowStatus.utilizedOverflow ?? false;
      case ReportTypeEnum.Unused:
        return overflowStatus.unusedOverflow ?? false;
      default:
        return false;
    }
  }, [reportType, overflowStatus]);

  const estimateTimestamp = useMemo(() => {
    if (!currentDivisionId) return "";
    const partitionEstimates = estimates
      .filter(
        e =>
          e.values.partitionId === currentDivisionId && e.values.lastProcessed
      )
      .sort((a: any, b: any) =>
        a.values.lastProcessed >= b.values.lastProcessed ? -1 : 1
      );
    if (partitionEstimates[0] && partitionEstimates[0].values?.lastProcessed) {
      return formatTime(partitionEstimates[0].values.lastProcessed);
    }
    return "";
  }, [estimates, currentDivisionId]);

  const codebooksTimestamp = useMemo(() => {
    const activityCb =
      currentDivisionId &&
      codebooks.find(c => (c.partitionId = currentDivisionId));
    if (activityCb && activityCb?.lastProcessed)
      return formatTime(activityCb.lastProcessed);
    return "";
  }, [codebooks, currentDivisionId]);

  const unusedData = useMemo(() => {
    return allUnusedData.filter(a => a.partitionId === currentDivisionId);
  }, [allUnusedData, currentDivisionId]);

  const missingData = useMemo(() => {
    return allMissingData.filter(a => a.partitionId === currentDivisionId);
  }, [allMissingData, currentDivisionId]);

  const utilizedData = useMemo(() => {
    return Object.values(allUtilizedData).filter(
      a => a.partitionId === currentDivisionId
    );
  }, [allUtilizedData, currentDivisionId]);

  const hasNoCodebooksData = useMemo(() => {
    if (unusedData.length <= 0 && utilizedData.length <= 0) return true;
    return false;
  }, [unusedData, utilizedData]);

  return {
    currentDivisionId,
    setCurrentDivisionId,
    utilizedLoaded,
    utilizedDetailsLoaded,
    unusedLoaded,
    unusedData,
    missingLoaded,
    missingData,
    isCurrentReportOverflow,
    reportType,
    setReportType,
    selectedUtilizedCode,
    setSelectedUtilizedCode,
    hasNoCodebooksData,
    codebooksTimestamp,
    estimateTimestamp
  };
};
const [provider, context] = constate(useCodebooks);
export const CodebooksProvider = provider;
export const useCodebooksContext = context;
