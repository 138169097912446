import { SchemaField } from "api";
import {
  IProductDto,
  IVendorDtoV1Response
} from "api/GeneratedClients/ContactsClient";
import { CompanyTypeDto } from "api/GeneratedClients/contacts";
import { IVendorLocationDto } from "api/contacts-api-dto-interfaces";
import { TextOverflow } from "core";
import { Overlay } from "hcss-components";
import { Flex } from "hcss-core";
import { strings } from "localization";
import { useAuthorization, usePermissions } from "modules/account";
import { selectors as contactsSelectors } from "modules/contacts";
import { CompanyPopover } from "modules/contacts/components/contact-popover";
import {
  WebAddress,
  PhoneNumber
} from "modules/contacts/components/vendor-shared";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  memo
} from "react";
import { useSelector } from "react-redux";
import { DropdownList } from "react-widgets";
import styled from "styled-components";
import { CompanyAvatar } from "../../../../core/components/avatar";
import { SchemaFormFieldInputProps } from "./common";
import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import ConfirmDelete from "../../../../core/components/modals/ConfirmDelete";

export interface CompanyFieldData {
  id: string;
  code: string;
  name: string | undefined;
  webAddress: string | undefined;
  phoneNumber: string | undefined;
  type: CompanyTypeDto | undefined;
  products: IProductDto[] | undefined;
  offices: IVendorLocationDto[] | undefined;
}

export interface CompanyListOption {
  display: string;
  value: CompanyFieldData | null;
  id: string;
}

export interface StaticCompanyFieldInputProps {
  editable?: boolean;
  value?: CompanyFieldData | string | null;
  onDelete: (item: CompanyFieldData) => void;
}
export const StaticCompanyFieldInput = memo(
  (props: StaticCompanyFieldInputProps) => {
    const { editable, value, onDelete } = props;

    const vendorDataOptions = useSelector(
      contactsSelectors.getVendorDataOptions
    );

    const selectedVendor = useMemo(() => {
      if (typeof value === "string") {
        const vendor = vendorDataOptions.find(v => v.id === value);
        return vendor;
      }
      return value;
    }, [value, vendorDataOptions]);

    const handleDelete = useCallback(() => {
      if (selectedVendor) onDelete(selectedVendor);
    }, [selectedVendor, onDelete]);

    return (
      <Flex alignItems="center" justifyContent="flex-start">
        <CompanyBadge vendor={selectedVendor} />
        {editable && value && (
          <IconButton onClick={handleDelete}>
            <Cancel />
          </IconButton>
        )}
      </Flex>
    );
  }
);

const ItemComponent = ({ item }: { item: CompanyListOption }) =>
  item?.value ? <CompanyOption {...item.value} /> : <div>{`${"(clear)"}`}</div>;

export interface SingleCompanyFieldInputProps {
  schemaField: SchemaField;
  editable?: boolean;
  value?: CompanyFieldData | string | null;
  onChange: (value?: CompanyFieldData) => void;
  hasErrors?: boolean;
  keepInvalidValues?: boolean;
}
export const SingleCompanyFieldInput = memo(
  (props: SingleCompanyFieldInputProps) => {
    const {
      editable,
      value,
      hasErrors,
      onChange,
      schemaField: { config },
      keepInvalidValues
    } = props;

    const filtersSelected: boolean =
      config?.filterEnabled && config.filters?.length > 0;
    const vendorDataOptions = useSelector(
      contactsSelectors.getVendorDataOptions
    );

    const selectedVendor = useMemo(() => {
      if (typeof value === "string") {
        const vendor = vendorDataOptions.find(v => v.id === value);
        return vendor;
      }
      return value;
    }, [value, vendorDataOptions]);

    const options: CompanyListOption[] = useMemo(() => {
      let data: CompanyListOption[] = vendorDataOptions.map(data => {
        return {
          display: data.code,
          value: data,
          code: data.code,
          id: data.id
        };
      });

      if (filtersSelected) {
        data = data.filter(op => {
          return config?.filters.includes(op.value?.type?.code);
        });
      }

      if (selectedVendor) {
        data.push({ display: "(clear)", value: null, id: "" });
      }

      return data;
    }, [vendorDataOptions, filtersSelected, selectedVendor]);

    const companiesExist: boolean = options.length > 0;

    const handleChange = useCallback(
      (option?: CompanyListOption) => {
        const data = option?.value;
        if (data) {
          onChange(data);
        } else {
          onChange();
        }
      },
      [onChange]
    );

    useEffect(() => {
      if (keepInvalidValues || !selectedVendor) {
        return;
      }

      const existSelectedOptions = options.filter(
        d => d.id === selectedVendor.id
      );

      if (!existSelectedOptions) {
        handleChange();
      }
    }, [selectedVendor, options, handleChange, keepInvalidValues]);

    return (
      <Flex alignItems="center" justifyContent="flex-start">
        <CompanyBadge vendor={selectedVendor} />
        {editable && (
          <div id="arrow-dropdown-container">
            <DropdownContainer hasErrors={hasErrors}>
              <DropdownList
                filter={(option, search) =>
                  CompanyDropdownFilter(option, search)
                }
                textField="display"
                valueField="id"
                defaultValue={selectedVendor}
                value={selectedVendor}
                data={options}
                onChange={handleChange}
                id="arrow-dropdown"
                messages={{
                  emptyList:
                    companiesExist && filtersSelected
                      ? strings.projects.setup.message
                          .emptyCompanyListWithFilter
                      : strings.projects.setup.message.emptyCompanyList
                }}
                itemComponent={ItemComponent}
              />
            </DropdownContainer>
          </div>
        )}
      </Flex>
    );
  }
);

interface MultiCompanyListProps {
  value?: CompanyFieldData | CompanyFieldData[] | string | null;
  editable?: boolean;
  onDelete: (item: CompanyFieldData) => void;
}
const MultiCompanyList = memo((props: MultiCompanyListProps) => {
  const { editable, value, onDelete } = props;

  if (Array.isArray(value)) {
    if (value.length > 0) {
      return (
        <StaticInputContainer>
          {value?.map((selectedVendor: CompanyFieldData) => (
            <StaticCompanyFieldInput
              key={selectedVendor.id}
              value={selectedVendor}
              editable={editable}
              onDelete={onDelete}
            />
          ))}
        </StaticInputContainer>
      );
    } else {
      return (
        <StaticCompanyFieldInput
          value={null}
          editable={editable}
          onDelete={onDelete}
        />
      );
    }
  }

  return (
    <StaticCompanyFieldInput
      value={value}
      editable={editable}
      onDelete={onDelete}
    />
  );
});

export interface MultiCompanyFieldInputProps {
  schemaField: SchemaField;
  editable?: boolean;
  value?: CompanyFieldData | CompanyFieldData[] | string | string[] | null;
  onChange: (value?: CompanyFieldData[]) => void;
  hasErrors: boolean;
  keepInvalidValues?: boolean;
}
export const MultiCompanyFieldInput = memo(
  (props: MultiCompanyFieldInputProps) => {
    const {
      editable,
      value,
      onChange,
      schemaField: { config },
      schemaField,
      hasErrors,
      keepInvalidValues
    } = props;

    const filtersSelected: boolean =
      config?.filterEnabled && config.filters?.length > 0;
    const vendorDataOptions = useSelector(
      contactsSelectors.getVendorDataOptions
    );

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [confirmDeleteItem, setConfirmDeleteItem] = useState<
      CompanyFieldData | undefined
    >();

    const convertToCompany = (
      val: string | CompanyFieldData
    ): CompanyFieldData | undefined => {
      if (typeof val !== "string") {
        return val;
      }

      const vendor = vendorDataOptions.find(v => v.id === val);

      return vendor;
    };

    const valueArray = useMemo(() => {
      if (!value) {
        return [];
      }

      if (Array.isArray(value)) {
        const companiesData = value
          .map(convertToCompany)
          .filter(x => !!x) as CompanyFieldData[];

        return [...companiesData];
      }

      const company = convertToCompany(value);
      return company ? [company] : [];
    }, [value, vendorDataOptions]);

    const handleAdd = useCallback(
      (option?: CompanyListOption) => {
        const data = option?.value;
        if (data) {
          onChange([...valueArray, data]);
          setAddValue(null);
        }
      },
      [onChange, valueArray]
    );

    const filteredOptions: CompanyListOption[] = useMemo(() => {
      let data: CompanyListOption[] = vendorDataOptions.map(data => {
        return {
          display: data.code,
          value: data,
          code: data.code,
          id: data.id
        };
      });

      if (filtersSelected) {
        data = data.filter(op => {
          return config?.filters.includes(op.value?.type?.code);
        });
      }

      return data;
    }, [vendorDataOptions, filtersSelected]);

    const options: CompanyListOption[] = useMemo(() => {
      let data = filteredOptions;

      const existIds = valueArray.map(v => v.id);
      data = data.filter(op => {
        return !existIds.includes(op.id);
      });

      return data;
    }, [filteredOptions, valueArray]);

    useEffect(() => {
      if (keepInvalidValues || !valueArray) {
        return;
      }

      const filtered = valueArray.filter(v =>
        filteredOptions.some(d => d.id === v.id)
      );
      if (filtered.length != valueArray.length) onChange(filtered);
    }, [valueArray, filteredOptions, onChange, keepInvalidValues]);

    const companiesExist: boolean = options.length > 0;

    const openDeleteModal = useCallback(
      (item: CompanyFieldData) => {
        setConfirmDeleteItem(item);
        setShowConfirmDeleteModal(true);
      },
      [setConfirmDeleteItem, setShowConfirmDeleteModal]
    );

    const handleDelete = useCallback(
      (id: string) => {
        onChange(valueArray.filter(v => v.id !== id));
      },
      [onChange, valueArray]
    );

    const [addValue, setAddValue] = useState<CompanyListOption | null>();

    return (
      <>
        {editable && (
          <MultiDropdownContainer hasErrors={hasErrors}>
            <MultiDividerLine />
            <ArrowDropdownContainer id="arrow-dropdown-container">
              <DropdownList
                filter={(option, search) =>
                  CompanyDropdownFilter(option, search)
                }
                textField="display"
                valueField="id"
                data={options}
                onChange={handleAdd}
                id="arrow-dropdown"
                value={addValue}
                messages={{
                  emptyList:
                    companiesExist && filtersSelected
                      ? strings.projects.setup.message
                          .emptyCompanyListWithFilter
                      : strings.projects.setup.message.emptyCompanyList
                }}
                itemComponent={ItemComponent}
              />
            </ArrowDropdownContainer>
          </MultiDropdownContainer>
        )}

        <MultiCompanyList
          editable={editable}
          value={valueArray}
          onDelete={openDeleteModal}
        />

        <ConfirmDelete
          show={showConfirmDeleteModal}
          handleClose={() => setShowConfirmDeleteModal(false)}
          handleDelete={() => {
            if (confirmDeleteItem) handleDelete(confirmDeleteItem.id);
            setShowConfirmDeleteModal(false);
          }}
          title={schemaField.name}
        >
          <p>
            {strings.formatString(
              strings.core.modals.confirmDelete.message,
              confirmDeleteItem?.name ?? ""
            )}
          </p>
        </ConfirmDelete>
      </>
    );
  }
);

export const CompanyInput = memo((props: SchemaFormFieldInputProps) => {
  const { form, field, schemaField, isLocked } = props;
  const permissions = usePermissions();
  const authorization = useAuthorization();
  const config = schemaField.config;
  const selectedVendor: CompanyFieldData | CompanyFieldData[] | null =
    field.value;

  const adminFailure = !permissions.admin && config.adminOnly;
  const fieldIsEditable =
    permissions.write && authorization.canAccessLimitedPrecon && !adminFailure;

  const handleSingleChange = useCallback(
    (data?: CompanyFieldData) => {
      form.setFieldValue(field.name, data);
    },
    [form.setFieldValue]
  );

  const handleMultiSelectChange = useCallback(
    (data?: CompanyFieldData[]) => {
      form.setFieldValue(field.name, data);
    },
    [form.setFieldValue]
  );

  if (config?.multiSelect) {
    return (
      <MultiCompanyFieldInput
        hasErrors={!!(form.errors[field.name] && form.touched[field.name])}
        value={selectedVendor}
        editable={fieldIsEditable && !isLocked}
        schemaField={schemaField}
        keepInvalidValues
        onChange={handleMultiSelectChange}
        data-testid="multi-company-field"
      />
    );
  } else {
    return (
      <SingleCompanyFieldInput
        hasErrors={!!(form.errors[field.name] && form.touched[field.name])}
        value={selectedVendor as CompanyFieldData | null}
        editable={fieldIsEditable && !isLocked}
        schemaField={schemaField}
        onChange={handleSingleChange}
        keepInvalidValues
        data-testid="single-company-field"
      />
    );
  }
});

export const CompanyOption = memo((props: CompanyFieldData) => {
  const { code, name } = props;
  const optionRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  if (!props.id) return <div>{`${"(clear)"}`}</div>;
  return (
    <OptionContainer
      ref={optionRef}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => setShow(false)}
      onClick={e => {
        setShow(false);
      }}
    >
      <OptionHeader>
        <TextOverflow className="option-code">{code}</TextOverflow>
        <TextOverflow className="option-name">{name}</TextOverflow>
      </OptionHeader>
      <Overlay
        rootClose
        show={show}
        placement="right"
        target={optionRef.current ?? undefined}
      >
        <CompanyPopover {...props} setShow={setShow} />
      </Overlay>
    </OptionContainer>
  );
});

export const CompanyDropdownFilter = (
  { value }: CompanyListOption,
  search: string
) => {
  if (!value) return false;
  return (
    value.code.toLowerCase().includes(search.toLowerCase()) ||
    (value.name
      ? value.name.toLowerCase().includes(search.toLowerCase())
      : false)
  );
};

interface CompanyBadgeProps {
  vendor: CompanyFieldData | IVendorDtoV1Response | undefined | null;
}
export const CompanyBadge = memo((props: CompanyBadgeProps) => {
  const { vendor } = props;
  return (
    <>
      <CompanyAvatar
        name={vendor?.name ? vendor.name : ""}
        id={vendor?.id ? vendor.id : vendor?.code}
        size="57.5px"
      />
      <Flex
        flexDirection="column"
        marginLeft="12px"
        color="rgb(64, 64, 64)"
        style={{ width: "100%", zIndex: "999", lineHeight: "2rem" }}
      >
        <Flex
          fontSize="1.6rem"
          style={{ whiteSpace: "nowrap", maxWidth: "80%" }}
        >
          <TextOverflow style={{ maxWidth: "fit-content", fontWeight: "600" }}>
            {vendor?.code}
          </TextOverflow>
          <TextOverflow style={{ maxWidth: "fit-content" }}>
            &nbsp;- {vendor?.name}
          </TextOverflow>
        </Flex>
        {vendor?.webAddress && (
          <Flex
            style={{
              maxWidth: "80%",
              whiteSpace: "nowrap",
              overflow: "hidden"
            }}
          >
            <WebAddress webAddress={vendor.webAddress} />
          </Flex>
        )}
        {vendor?.phoneNumber && (
          <Flex style={{ maxWidth: "80%", whiteSpace: "nowrap" }}>
            <PhoneNumber phoneNumber={vendor.phoneNumber} />
          </Flex>
        )}
      </Flex>
    </>
  );
});

const DropdownContainer = styled.div<{ hasErrors?: boolean }>`
  & .rw-widget-input {
    height: 34px;
    ${props => (props.hasErrors ? "border: 1px solid #a94442;" : "")};
  }
}
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionHeader = styled.div`
  display: flex;

  .option-code {
    white-space: nowrap;
    flex: 1;
    font-size: 13px;
    font-weight: 700;
    margin-right: 3px;
  }

  .option-name {
    white-space: nowrap;
    flex: 2;
    font-size: 13px;
  }
`;

const MultiDropdownContainer = styled.div<{ hasErrors?: boolean }>`
  height: 40px;
  display: flex;
  ${props => (props.hasErrors ? "border: 1px solid #a94442;" : "")};
`;

const ArrowDropdownContainer = styled.div`
  flex: 1;
`;

const MultiDividerLine = styled.hr`
  flex: 1;
`;

const StaticInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
