import React from "react";
import styled from "styled-components";
import { formatPhoneNumber, TextOverflow } from "core";
import { Icon } from "hcss-components";
import { IAddress } from "api/GeneratedClients/ContactsClient";

export const StyledIcon = styled(Icon)`
  margin-right: 1rem;
  font-size: 1.5rem;
  width: 1em;
`;

export const WebAddress = ({
  webAddress
}: {
  webAddress: string | undefined;
}) => {
  if (!webAddress) return null;
  let url = webAddress;
  if (!webAddress.startsWith("http") && !webAddress.startsWith("https")) {
    url = `https://${webAddress}`;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden"
      }}
    >
      <StyledIcon name="link" />
      <TextOverflow>
        <a
          style={{
            lineHeight: "auto",
            wordBreak: "break-word"
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          {webAddress}
        </a>
      </TextOverflow>
    </div>
  );
};

export const PhoneNumber = ({
  phoneNumber
}: {
  phoneNumber: string | undefined;
}) => {
  if (!phoneNumber) return null;
  return (
    <div style={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
      <StyledIcon name="phone" />
      <a
        style={{
          lineHeight: "auto",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
        href={`tel:${encodeURIComponent(phoneNumber)}`}
      >
        {formatPhoneNumber(phoneNumber)}
      </a>
    </div>
  );
};

export const VendorLocationAddress = ({
  address
}: {
  address: IAddress | undefined;
}) => {
  const displayCityStateZip = (address: IAddress) => {
    if (
      !address?.city &&
      !address?.stateProvince &&
      !address?.postalCode &&
      !address?.countryRegion
    ) {
      return null;
    }

    return (
      <div>
        {address.city ?? ""}
        {address.city && (address.stateProvince || address.postalCode) && ", "}
        {address.stateProvince ?? ""}
        {address.postalCode && ` ${address.postalCode}`}
        {address.countryRegion &&
          (address.city || address.stateProvince || address.postalCode) &&
          ", "}
        {address.countryRegion && `${address.countryRegion}`}
      </div>
    );
  };

  if (
    !address?.addressLine1 &&
    !address?.addressLine2 &&
    !address?.city &&
    !address?.stateProvince &&
    !address?.postalCode &&
    !address?.countryRegion
  ) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      <div>
        <StyledIcon name="compass" />
      </div>
      <a
        href={`http://maps.google.com/?q=${encodeURIComponent(
          `${address.addressLine1} ${address.addressLine2} ${address.city} ${address.stateProvince} ${address.postalCode} ${address.countryRegion}`
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>{address.addressLine1}</div>
        {address.addressLine2 && <div>{address.addressLine2}</div>}
        {displayCityStateZip(address)}
      </a>
    </div>
  );
};
